import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

// Hooks
import useSiteMetadata from '../../../hooks/useSiteMetadataQuery'

// Icons
import favicon from '../../../../static/assets/icon.png'

const Seo = (props) => {
  const { site: { siteMetadata }, allPrismicGlobal } = useSiteMetadata()

  // Pull and reformat relevant data from `allPrismicGlobal`
  const cmsMetadata = {
    description: allPrismicGlobal?.nodes[0]?.data?.sitewide_meta_description?.text,
    image: allPrismicGlobal?.nodes[0]?.data?.sitewide_social_image?.url
  }

  /**
   * Filter any items which are empty/null/undefined
   * @param  {Object)
   * @return {Object}
   */
  const definedProps = (obj) => Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => {
      // Only return keys which have a value
      return !!v
    })
  )

  // Merge all of the data, destructuring into variables
  const {
    title,
    customTitle,
    siteName,
    description,
    image,
    robots,
    siteUrl,
    slug
  } = Object.assign({}, siteMetadata, definedProps(cmsMetadata), definedProps(props))

  // Generate a full URL to the content & image path
  const url = slug ? (siteUrl + slug) : siteUrl

  const imageUrl = image || `${siteUrl}/assets/icon.png`

  // setup google tag manager
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const script = document.createElement('script')
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MC36NJS');`
      document.head.appendChild(script)
    }
  }
  , [])

  // setup LinkedIn pixel
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const _linkedin_partner_id = '4007313'
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      (function (l) {
        if (!l) {
          window.lintrk = function (a, b) {
            window.lintrk.q.push([a, b])
          }
          window.lintrk.q = []
        }
        var s = document.getElementsByTagName('script')[0]
        var b = document.createElement('script')
        b.type = 'text/javascript'
        b.async = true
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
        s.parentNode.insertBefore(b, s)
      })(window.lintrk)
    }
  }, [])

  // Setup Loqate:
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      window.pca = window.pca || {}
      window.pca.initial = {
        accountCode: 'NORTH11595',
        host: 'NORTH11595.pcapredict.com'
      }
      window.pca.on = window.pca.on || function () {
        (window.pca.onq = window.pca.onq || []).push(arguments)
      }
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = '//NORTH11595.pcapredict.com/js/sensor.js'
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }, [])

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      /* If a customTitle is passed, don't use the `titleTemplate`, instead use
      the customTitle passed as a prop */
      title={customTitle || ''}
      titleTemplate={customTitle ? '' : `%s | ${siteName}`}
      defaultTitle={siteName}
    >
      <title>{customTitle || title}</title>

      {/* Resource Hints */}
      {['https://forms.hsforms.com', 'https://js.hsforms.net'].map(resource => <link key={resource} rel='preconnect dns-prefetch' href={resource} />)}

      {/* Trim it if it's too long */}
      <meta name='description' content={description.slice(0, 300)} />

      {/* Robots tag, if a prop is passed */}
      {robots && <meta name='robots' content={robots} />}

      {/* Canonical URL, use the custom one if it's passed as a prop */}
      <link rel='canonical' href={props.customCanonicalUrl || url} />

      {/* Open Graph Tags */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={customTitle || (title ? `${title} | ${siteName}` : siteName)} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={imageUrl} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content='@northcoders' />

      <meta name='google-site-verification' content='6IJYnOzzDDNsbKfYHxTC1II0Y8UCycmbkmxvoK0e7Vo' />
      {/* <meta name='facebook-domain-verification' content='6r8ii8y58923jjzyvl4l6hj6j3anhv' /> */}
      <meta name='p:domain_verify' content='5ce8f8fc27054cbb983783f044a71880' />

      {/* Favicon */}
      <link rel='shortcut icon' type='image/gif' href={favicon} />

      {/* For LinkedIn ad tracking */}
      {/* <noscript>
        <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4007313&fmt=gif" />
      </noscript> */}
    </Helmet>
  )
}

Seo.propTypes = {
  customCanonicalUrl: PropTypes.string,
  customTitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  isHomepage: PropTypes.bool,
  robots: PropTypes.string,
  siteName: PropTypes.string,
  siteUrl: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string
}

export default Seo
